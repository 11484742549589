import _isArguments from "es5-ext/function/is-arguments";
import _isString from "es5-ext/string/is-string";
import _array from "./array";
import _string from "./string";
import _validIterable from "./valid-iterable";
import _es6Symbol from "es6-symbol";
var exports = {};
var isArguments = _isArguments,
    isString = _isString,
    ArrayIterator = _array,
    StringIterator = _string,
    iterable = _validIterable,
    iteratorSymbol = _es6Symbol.iterator;

exports = function (obj) {
  if (typeof iterable(obj)[iteratorSymbol] === "function") return obj[iteratorSymbol]();
  if (isArguments(obj)) return new ArrayIterator(obj);
  if (isString(obj)) return new StringIterator(obj);
  return new ArrayIterator(obj);
};

export default exports;